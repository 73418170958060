import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Pagination from "../Pagination";
import useFetchChunkAudios from "../../hooks/useFetchChunkAudios";
import useFetchFullAudio from "../../hooks/useFetchFullAudio";
import Buttons from "./Buttons";
import endpoint from "../../utils/endpointData";
import { useAuth } from "../../store/AuthContext"; // Import the useAuth hook

function AnalyzeSpeech() {
  const { speechId } = useParams();
  const navigate = useNavigate();
  const { chunkAudios, setChunkAudios, isTranscribed, loading, setLoading, setIsTranscribed, totalChunks, currentPage, setCurrentPage } = useFetchChunkAudios(speechId);
  const { fullAudio, fullTranscript, fetchFullAudio } = useFetchFullAudio();
  const chunksPerPage = 10;
  const [editedChunks, setEditedChunks] = useState({});
  const { userRole } = useAuth();

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage when speechId changes
  }, [speechId, setCurrentPage]);

  const handleTranscribeAll = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
        return;
      }
      const response = await fetch(`${endpoint}audios/${speechId}/transcribe`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          navigate("/");
        }
        throw new Error("Failed to transcribe audio files");
      }
      setIsTranscribed(true);
    } catch (error) {
      console.error("Error transcribing audio files:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchTranscription = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
        return;
      }
      const response = await fetch(`${endpoint}audios/${speechId}/get/transcript`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          navigate("/");
        }
        throw new Error("Failed to fetch transcription");
      }
      const { transcript } = await response.json();
      const updatedChunkAudios = chunkAudios.map((audio) =>
        audio.id === speechId ? { ...audio, text: transcript } : audio
      );
      setChunkAudios(updatedChunkAudios);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transcription:", error);
      setLoading(false);
    }
  };

  const handleTextChange = (chunkId, newText) => {
    setEditedChunks({ ...editedChunks, [chunkId]: newText });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
        return;
      }
      const editedChunksData = Object.keys(editedChunks).map((chunkId) => ({
        chunkId,
        editedText: editedChunks[chunkId],
      }));

      const response = await fetch(`${endpoint}update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editedChunksData),
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/login");
        }
        throw new Error("Failed to update transcripts");
      }
    } catch (error) {
      console.error("Error updating transcripts:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleConfirmTranscript = async (chunkId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
        return;
      }
      const response = await fetch(`${endpoint}confirmTranscript/${chunkId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/");
        }
        throw new Error("Failed to confirm transcript");
      }
      const updatedChunkAudios = chunkAudios.map((audio) =>
        audio.id === chunkId ? { ...audio, true_text: audio.text } : audio
      );
      setChunkAudios(updatedChunkAudios);
    } catch (error) {
      console.error("Error confirming transcript:", error);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber); // Update currentPage state to trigger re-fetch in useFetchChunkAudios hook
  };
console.log(chunkAudios);

  return (
    <div className="p-4 flex flex-col flex-grow">
      <h2 className="text-2xl font-bold mb-4">Speech Analysis</h2>
      <Pagination
        chunksPerPage={chunksPerPage}
        totalChunks={totalChunks} // Pass totalChunks for correct pagination
        paginate={paginate}
        currentPage={currentPage}
      />
      <Buttons
        isTranscribed={isTranscribed}
        loading={loading}
        handleTranscribeAll={handleTranscribeAll}
        handleFetchTranscription={handleFetchTranscription}
        handleSubmit={handleSubmit}
        hasEditedChunks={Object.keys(editedChunks).length > 0}
      />
      <div className="flex flex-col flex-grow overflow-auto">
        {loading && <p>Loading chunk audios...</p>}
        {!loading && (
          <>
            <table className="table-auto w-full border-collapse mb-4">
              <thead>
                <tr>
                  <th className="bg-gray-200 text-gray-700">Order</th>
                  <th className="px-4 py-2 bg-gray-200 text-gray-700">Interval</th>
                  <th className="px-4 py-2 bg-gray-200 text-gray-700">Audio</th>
                  <th className="px-4 py-2 bg-gray-200 text-gray-700">Text</th>
                  <th className="px-4 py-2 bg-gray-200 text-gray-700">Status</th>
                  {userRole === 'admin' && <th className="px-4 py-2 bg-gray-200 text-gray-700">Actions</th>}
                </tr>
              </thead>
              <tbody>
                {chunkAudios.map((audio) => (
                  <tr key={audio.id}>
                    <td className="border px-4 py-2">{audio.chunk_number}</td>
                    <td className="border px-4 py-2">{audio.interval}</td>
                    <td className="border px-4 py-2">
                      <audio controls>
                        <source src={audio.url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </td>
                    <td className="border px-4 py-2 w-full">
                      <textarea
                        value={editedChunks[audio.id] !== undefined ? editedChunks[audio.id] : audio.editedTranscript}
                        onChange={(e) => handleTextChange(audio.id, e.target.value)}
                        className={`w-full ${editedChunks[audio.id] ? 'bg-yellow-100' : ''}`}
                      />
                    </td>
                    <td className="border px-4 py-2">{audio.status}</td>
                    {userRole === 'admin' && (
                      <td className="border px-4 py-2">
                        {audio.editedTranscript === audio.trueTranscript ? (
                          <button className="bg-green-500 text-white px-2 py-1 rounded" disabled={true}>
                            Confirmed
                          </button>
                        ) : (
                          <button
                            onClick={() => handleConfirmTranscript(audio.id)}
                            className="bg-blue-500 text-white px-2 py-1 rounded"
                          >
                            Confirm Transcript
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              chunksPerPage={chunksPerPage}
              totalChunks={totalChunks} // Pass totalChunks for correct pagination
              paginate={paginate}
              currentPage={currentPage}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default AnalyzeSpeech;
