import { useState } from "react";
import  endpoint  from "../utils/endpointData";
import { useNavigate } from "react-router-dom";

const useFetchFullAudio = () => {
  const [fullAudio, setFullAudio] = useState(null);
  const [fullTranscript, setFullTranscript] = useState(null);
  const navigate = useNavigate();

  const fetchFullAudio = async (speechId, setLoading) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
        return;
      }
      const response = await fetch(`${endpoint}transcript/${speechId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          navigate("/");
        }
        throw new Error("Failed to fetch full audio");
      }
      const data = await response.json();
      console.log(data);
      
      setFullAudio(data.fullAudio);
      setFullTranscript(data.fullTranscript);
    } catch (error) {
      console.error("Error fetching full audio:", error);
    } finally {
      setLoading(false);
    }
  };

  return { fullAudio, fullTranscript, fetchFullAudio };
};

export default useFetchFullAudio;
