import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import endpoint from "../utils/endpointData";

const useFetchChunkAudios = (speechId, initialPage = 1, limit = 10) => {
  const [chunkAudios, setChunkAudios] = useState([]);
  const [isTranscribed, setIsTranscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalChunks, setTotalChunks] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [chunksPerPage] = useState(limit);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchChunkAudios() {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/");
          return;
        }
        const response = await fetch(`${endpoint}audios/${speechId}?page=${currentPage}&limit=${chunksPerPage}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          if (response.status === 401) {
            navigate("/");
          }
          throw new Error("Failed to fetch chunk audios");
        }
        const { chunks, isTranscribed, totalChunks } = await response.json();

        setIsTranscribed(isTranscribed);
        setTotalChunks(totalChunks);

        const audioPromises = chunks.map(async (chunk) => {
          const chunkResponse = await fetch(`${endpoint}audios/${speechId}/${chunk.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!chunkResponse.ok) {
            throw new Error(`Failed to fetch audio for chunk with ID ${chunk.id}`);
          }
          const audioBlob = await chunkResponse.blob();
          const audioUrl = URL.createObjectURL(audioBlob);
          return {
            ...chunk,
            url: audioUrl,
          };
        });

        const chunkAudiosWithData = await Promise.all(audioPromises);
        setChunkAudios(chunkAudiosWithData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching chunk audios:", error);
        setLoading(false);
      }
    }

    fetchChunkAudios();
  }, [speechId, currentPage, chunksPerPage, navigate]);

  return { chunkAudios, setChunkAudios, isTranscribed, loading, setLoading, setIsTranscribed, totalChunks, currentPage, setCurrentPage };
};

export default useFetchChunkAudios;
