import React from 'react';
import { Link } from 'react-router-dom';

function ErrorPage() {
    console.log('aefeaf');
    
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.text}>Page Not Found</p>
      <Link to="/" style={styles.link}>Go back to the homepage</Link>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px'
  },
  heading: {
    fontSize: '72px',
    marginBottom: '10px'
  },
  text: {
    fontSize: '24px',
    marginBottom: '20px'
  },
  link: {
    color: '#007bff',
    textDecoration: 'none'
  }
};

export default ErrorPage;
