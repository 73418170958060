import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Document, Packer, Paragraph } from "docx";
import { saveAs } from "file-saver";
import endpoint from "../../utils/endpointData";

const FullTranscript = () => {
  const { speechId } = useParams();
  const [fullAudio, setFullAudio] = useState("");
  const [fullTranscript, setFullTranscript] = useState("");
  const [editedFullTranscript, setEditedFullTranscript] = useState("");
  const [displayedTranscript, setDisplayedTranscript] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const fetchFullAudioAndTranscript = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const response = await fetch(`${endpoint}transcript/${speechId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch full audio and transcript");
      }
      const data = await response.json();
      console.log(data.fullTranscript);
      
      setFullAudio(`data:audio/mpeg;base64,${data.audio}`);
      setFullTranscript(data.fullTranscript);
      setDisplayedTranscript(data.fullTranscript);
    } catch (error) {
      console.error("Error fetching full audio and transcript:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEditedFullTranscript = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const response = await fetch(`${endpoint}edited-transcript/${speechId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch edited full transcript");
      }
      const data = await response.json();
      console.log(data.editedFullTranscript);
      
      setEditedFullTranscript(data.editedFullTranscript);
      setDisplayedTranscript(data.editedFullTranscript);
    } catch (error) {
      console.error("Error fetching edited full transcript:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTextChange = (event) => {
    const newText = event.target.value;
    setDisplayedTranscript(newText);
    setIsChanged(newText !== (displayedTranscript === fullTranscript ? fullTranscript : editedFullTranscript));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const response = await fetch(`${endpoint}update/fulltranscript/${speechId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ editedTranscript: displayedTranscript }),
      });
      if (!response.ok) {
        throw new Error("Failed to submit transcript");
      }
      setIsChanged(false); // Reset change state after successful submission
    } catch (error) {
      console.error("Error submitting transcript:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph(displayedTranscript),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "transcript.docx");
    });
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Full Audio and Transcript</h2>
      <button onClick={fetchFullAudioAndTranscript} className="mr-4 bg-blue-500 text-white px-4 py-2 rounded">
        Show Full Original Transcript
      </button>
      <button onClick={fetchEditedFullTranscript} className="mr-4 bg-blue-500 text-white px-4 py-2 rounded">
        Show Edited Full Transcript
      </button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <audio controls className="w-full mb-4">
            <source src={fullAudio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <div className="bg-gray-100 p-4 rounded">
            <textarea
              value={displayedTranscript}
              onChange={handleTextChange}
              className={`w-full h-64 resize-none border ${
                isChanged ? "bg-yellow-100" : ""
              }`}
            />
            {isChanged && (
              <button
                onClick={handleSubmit}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
              >
                Submit Changes
              </button>
            )}
            <button
              onClick={handleDownload}
              className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
            >
              Download as DOC
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FullTranscript;
