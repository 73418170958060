import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./pages/Dashboard";
import AddSpeech from "./components/AddSpeech";
import AddUser from "./components/AddUser";
import ListSpeechs from "./components/ListSpeechs";
import AnalyzeSpeech from "./components/AnalyzeSpeech/AnalyzeSpeech";
import { AuthProvider } from './store/AuthContext';
import ErrorPage from "./pages/ErrorPage";
import FullAudio from './components/AnalyzeSpeech/FullAudio'
import "./index.css"
function App() {
  return (
    <AuthProvider>
      <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="add-speech" element={<AddSpeech />} />
          <Route path="add-user" element={<AddUser />} />
          <Route path="speechs" >
            <Route path="" element={<ListSpeechs />} />
            <Route path=":speechId" element={<AnalyzeSpeech />} />
            <Route path=":speechId/fulltranscript" element={<FullAudio />} />
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
    </AuthProvider>
    
  );
}

export default App;
