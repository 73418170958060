import React, { useState } from "react";
import endpoint from "../utils/endpointData";
import { Link } from "react-router-dom";

const AddSpeech = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [uploadType, setUploadType] = useState('file'); // 'file' or 'youtube'
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [language, setLanguage] = useState('azerbaijani'); // Default language

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadSuccess(false);
  };

  const handleUrlChange = (event) => {
    setYoutubeUrl(event.target.value);
    setUploadSuccess(false);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleUpload = () => {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    if (!token) {
      throw new Error('Token is missing');
    }
    setLoading(true);

    const apiEndpoint = `${endpoint}upload/file`;

    if (uploadType === 'file' && selectedFile) {
      const formData = new FormData();
      formData.append("audio", selectedFile);
      formData.append("language", language); // Append language to formData

      fetch(apiEndpoint, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            console.log("File uploaded successfully");
            setUploadSuccess(true);
          } else {
            console.error("Error uploading file:", response.statusText);
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (uploadType === 'youtube' && youtubeUrl) {
      fetch(apiEndpoint, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ youtubeUrl: youtubeUrl, language: language }), // Include language in the body
      })
        .then((response) => {
          if (response.ok) {
            console.log("YouTube URL converted successfully");
            setUploadSuccess(true);
          } else {
            console.error("Error converting YouTube URL:", response.statusText);
          }
        })
        .catch((error) => {
          console.error("Error converting YouTube URL:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log("No file selected or YouTube URL provided");
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="text-center">
          <svg className="animate-spin h-10 w-10 mr-3 ..." viewBox="0 0 24 24"></svg>
          <p className="mt-2">Loading...</p>
        </div>
      ) : uploadSuccess ? (
        <>
          <div className="text-center text-green-500 mt-4 mb-4">
            Upload successful!
          </div>
          <Link to='/dashboard/speechs'>All Speeches</Link>
        </>
      ) : (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-2xl font-semibold mb-4">Add New Speech</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Upload Type
            </label>
            <div>
              <label className="mr-4">
                <input
                  type="radio"
                  name="uploadType"
                  value="file"
                  checked={uploadType === 'file'}
                  onChange={() => setUploadType('file')}
                />
                <span className="ml-2">File</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="uploadType"
                  value="youtube"
                  checked={uploadType === 'youtube'}
                  onChange={() => setUploadType('youtube')}
                />
                <span className="ml-2">YouTube URL</span>
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Choose Language
            </label>
            <select
              value={language}
              onChange={handleLanguageChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="azerbaijani">Azerbaijani</option>
              <option value="english">English</option>
              <option value="russian">Russian</option>
            </select>
          </div>
          {uploadType === 'file' ? (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Choose Audio File
              </label>
              <input
                type="file"
                accept="audio/*"
                onChange={handleFileChange}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          ) : (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                YouTube URL
              </label>
              <input
                type="text"
                value={youtubeUrl}
                onChange={handleUrlChange}
                placeholder="Enter YouTube URL"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          )}
          <div className="flex items-center justify-between">
            <button
              onClick={handleUpload}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Upload
            </button>
            {uploadType === 'file' && selectedFile && (
              <span className="text-gray-600 text-sm">{selectedFile.name}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddSpeech;
