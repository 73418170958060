import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import endpoint from '../utils/endpointData';

function ListSpeeches() {
  const [speeches, setSpeeches] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSpeeches() {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        if (!token) {
          throw new Error('Token is missing');
        }

        const response = await fetch(`${endpoint}audios`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include token in the request headers
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 401) {
          console.log("error 401");
          navigate("/");
          throw new Error('Failed to fetch speeches');
        }

        const data = await response.json();
        const formattedSpeeches = data.map(speech => ({
          ...speech,
          date: formatDate(new Date(speech.upload_date)) // Format date
        }));

        setSpeeches(formattedSpeeches);
      } catch (error) {
        console.error('Error fetching speeches:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchSpeeches();
  }, [navigate]);

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  if (loading) {
    return <p>Loading speeches...</p>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">List of Speeches</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full border rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="border text-left px-3 py-2">Filename</th>
              <th className="border text-left px-3 py-2">Uploader</th>
              <th className="border text-left px-3 py-2">Date</th>
              <th className="border text-left px-3 py-2">Status</th>
              <th className="border text-left px-3 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {speeches.map(speech => (
              <tr key={speech.id} className="bg-gray-50">
                <td className="border px-3 py-2">{speech.filename}</td>
                <td className="border px-3 py-2">{speech.username}</td>
                <td className="border px-3 py-2">{speech.date}</td>
                <td className="border px-3 py-2">{speech.status}</td>
                <td className="border px-3 py-2">
                  {speech.status === 'completed' ? (
                    <Link to={`${speech.id}`}>
                      <button className="bg-blue-500 text-white px-4 py-2 rounded">Analyze Speech</button>
                    </Link>
                  ) : (
                    <button className="bg-gray-500 text-white px-4 py-2 rounded" disabled>Analyze Speech</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListSpeeches;
