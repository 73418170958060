// Dashboard.js
import React, { useEffect } from 'react';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';

const Dashboard = () => {
  const { userRole, userName, logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (isLoggedIn==false) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="flex h-screen">
      <div className="bg-gray-800 h-screen text-white w-2/12 py-4 px-6 flex flex-col justify-between">
        <div>
          <h2 className="text-2xl font-semibold">Dashboard</h2>
          <ul className="mt-4">
            <li className="mb-2">
              <Link
                to="add-speech"
                className="block py-2 px-4 bg-blue-500 text-white rounded-md text-center"
              >
                Add new Speech
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="speechs"
                className="block py-2 px-4 bg-blue-500 text-white rounded-md text-center"
              >
                Check All Speeches
              </Link>
            </li>
            {userRole === 'admin' && (
              <li className="mb-2">
                <Link
                  to="add-user"
                  className="block py-2 px-4 bg-blue-500 text-white rounded-md text-center"
                >
                  Add User
                </Link>
              </li>
            )}
          </ul>
        </div>
        <button
          onClick={handleLogout}
          className="py-2 px-4 bg-red-500 text-white rounded-md"
        >
          Logout
        </button>
      </div>

      <div className="w-10/12 py-4 px-2 h-screen overflow-y-auto">
        <h1 className="text-3xl mb-4 border-b-2 border-solid">
          <strong>Hello, {userName}</strong>
        </h1>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
