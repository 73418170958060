import React from "react";
import { Link } from "react-router-dom";
const AnalyzeSpeechButtons = ({
  isTranscribed,
  loading,
  handleTranscribeAll,
  handleFetchTranscription,
  handleSubmit,
  hasEditedChunks,
}) => {
  return (
    <>
    <Link to={'fulltranscript'} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4">See Full Audio and transcript</Link>
    <div className="mb-4 flex justify-between ">
      
      {!isTranscribed && (
        <button
          onClick={handleTranscribeAll}
          disabled={loading}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2 mt-4"
        >
          {loading ? "Transcribing..." : "Transcribe All"}
        </button>
      )}
      <button
        onClick={handleFetchTranscription}
        disabled={loading}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
      >
        {loading ? "Fetching..." : "Fetch All Transcripts"}
      </button>
      {isTranscribed && (
        <button
          onClick={handleSubmit}
          disabled={!hasEditedChunks}
          className={`bg-${hasEditedChunks ? 'blue-500 hover:bg-blue-700' : 'gray-400'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4`}
        >
          Submit Changes
        </button>
      )}
    </div>
    </>
  );
};

export default AnalyzeSpeechButtons;
